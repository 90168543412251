define("da-vinci/components/portal-integration-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showPortalRegisterModal}}
    <PortalIntegrationModal::RegisterModal
      @vkn={{vkn}}
      @email={{email}}
      @password={{password}}
      @popUpOpen={{true}}
      @onCancel={{onCancel}}
      @goBackToMainPage={{action goBackToMainPage}}
      @unvan={{unvan}}
      @stepIndicators={{hash steps=3 activeStep=2}}
    />
  {{else if showMainPageModal}}
    <TaxpayerIntegrationModal
      @popUpOpen={{true}}
      @onCancel={{onCancel}}
    />
  {{/if}}
  
  */
  {
    "id": "WX5Jbgae",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPortalRegisterModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"portal-integration-modal/register-modal\",[],[[\"@vkn\",\"@email\",\"@password\",\"@popUpOpen\",\"@onCancel\",\"@goBackToMainPage\",\"@unvan\",\"@stepIndicators\"],[[22,\"vkn\"],[22,\"email\"],[22,\"password\"],true,[22,\"onCancel\"],[28,\"action\",[[23,0,[]],[24,[\"goBackToMainPage\"]]],null],[22,\"unvan\"],[28,\"hash\",null,[[\"steps\",\"activeStep\"],[3,2]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showMainPageModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"taxpayer-integration-modal\",[],[[\"@popUpOpen\",\"@onCancel\"],[true,[22,\"onCancel\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/portal-integration-modal/template.hbs"
    }
  });

  _exports.default = _default;
});